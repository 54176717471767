import FullWidthSection from "../../../section/FullWidthSection";
import { Button } from "../../../../../../src/modules/_common/components/Button";
import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";

const SparkSection = ({ bgColor = "light-green", behindBoxClass = "bg-white", titleVariant = "spark" }) => {
	const { event } = useAnonymousDataLayer();

	return (
		<>
			<div className="section section-full-width">
				<div className="row m-0 colored-separator">
					<div className="top-color-1 bg-pink"></div>
					<div className={`top-color-2 ${bgColor === "red" ? "bg-light-green" : "bg-red"}`}></div>
					<div className="top-color-3 bg-purple"></div>
					<div className="top-color-4 bg-dark-purple"></div>
				</div>
			</div>

			<FullWidthSection
				bgColor={bgColor}
				className="spark-section py-10 py-md-20 px-4 d-flex justify-content-center align-items-center position-relative"
				containerClassName="row bg-white pt-7 pb-12 pt-md-15 pb-md-10 px-6 d-flex align-items-center justify-content-center flex-column border border-dark position-relative"
			>
				<div className="z-3 col-12 text-center " style={{ maxWidth: 1000 }}>
					<h2 className="pb-4 pb-md-6 h1 mx-auto d-none d-md-block">
						{titleVariant === "spark" ? (
							<>
								Subscribe to <span className="text-mid-green font-family-young">Spark!</span> for monthly perspectives
								from our founder on workplace transformation
							</>
						) : (
							<>Get the latest from Stay Nimble every month!</>
						)}
					</h2>
					<h2 className="pb-4 pb-md-6 mx-auto d-block d-md-none">
						{titleVariant === "spark" ? (
							<>Subscribe to our monthly newsletter</>
						) : (
							<>Subscribe to our monthly newsletter!</>
						)}
					</h2>
					{titleVariant === "spark" ? (
						<p className="font-size-lg m-0 mx-auto" style={{ maxWidth: 763 }}>
							Get curated insights on workplace evolution, team development, and organisational culture - with practical
							coaching strategies to help your team thrive.
							<br />
							<br />
							For leaders focused on building high-performing teams
						</p>
					) : (
						<p className="font-size-lg m-0 mx-auto" style={{ maxWidth: 763 }}>
							Get curated insights on workplace evolution, team development, and organisational culture - with practical
							coaching strategies to help your team thrive.
							<br />
							<br />
							For leaders focused on building high-performing teams
						</p>
					)}
				</div>
				<div className="z-3 col-12 d-flex align-items-center justify-content-center flex-column flex-md-row">
					<getresponse-form form-id="4b5cade4-1f75-46a8-a94b-14c0a97dc329" e="1"></getresponse-form>
				</div>
				<div className={`box-behind-main box-behind-main-10 ${behindBoxClass}`}></div>
			</FullWidthSection>
			<div className="section section-full-width">
				<div className="row m-0 colored-separator">
					<div className="bottom-color-1 bg-purple"></div>
					<div className="bottom-color-2 bg-dark-purple"></div>
					<div className="bottom-color-3 bg-mid-green"></div>
				</div>
			</div>
		</>
	);
};

export default SparkSection;
